const Pagination = ({ dataCount, currentPage, totalPage, page }) => {
    return (
        <>
            <div className='footer-pagination'>
                <ul className='paginations d-flex justify-content-end list-unstyled gap-2'>
                    {dataCount > 10 && (
                        <>
                            <li className={`pages-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => page(1)}>{'<<'}</li>
                            <li className={`pages-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => page(currentPage - 1)}>{'<'}</li>
                        </>
                    )}

                    {
                        dataCount > 10 && Array.from({ length: totalPage }, (_, i) => i + 1).map((pageNum, index) => {
                            // Show first 2 pages, last 2 pages, and current page +/- 1 with ellipsis in between
                            if (pageNum === 1 || pageNum === 2 || pageNum === totalPage || pageNum === totalPage - 1 ||
                                (pageNum >= currentPage - 1 && pageNum <= currentPage + 1)) {
                                return (
                                    <li key={index} className={`pages-item ${currentPage === pageNum ? 'active' : ''}`} onClick={() => page(pageNum)}>
                                        {pageNum}
                                    </li>
                                );
                            } else if (pageNum === currentPage - 2 || pageNum === currentPage + 2) {
                                return (
                                    <li key={index} className="pages-item dots">...</li>
                                );
                            }
                            return null;
                        })
                    }

                    {dataCount > 10 && (
                        <>
                            <li className={`pages-item ${currentPage === totalPage ? 'disabled' : ''}`} onClick={() => page(currentPage + 1)}>{'>'}</li>
                            <li className={`pages-item ${currentPage === totalPage ? 'disabled' : ''}`} onClick={() => page(totalPage)}>{'>>'}</li>
                        </>
                    )}
                </ul>
            </div>
        </>
    )
}

export { Pagination };
