
// SVGs
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal, Table } from 'react-bootstrap';

// Helpers
import { getApi, postApi } from "../../Helpers/Axios";
import { localTimeing } from "../../Helpers/Helper";

// SVGs
import ExportSvg from "../../assets/assets_svg/export.svg";
import LightExportSvg from "../../assets/assets_svg/lightexport.svg";
import WinnerSvg from "../../assets/assets_svg/winner.svg";
import WinnerYellowSvg from "../../assets/assets_svg/winner_yellow.svg";
import AddIconSvg from "../../assets/assets_svg/addicon.svg";

// Pagination
import { Pagination } from "../Pagination";

const Users = () => {

    const [modalShow, setModalShow] = useState(false);

    const [userData, setUserData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isExportStatus, setIsExportStatus] = useState(null);

    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        setIsLoadingData(true);
        fetchBanner();
    }, [currentPage, searchData]);

    const fetchBanner = async () => {
        let url = searchData !== '' ? `users?page=${currentPage}&search=${searchData}` : `users?page=${currentPage}`
        await getApi(url, true).then(async (response) => {
            if (response.status === 200) {
                setUserData(response?.data);
                setDataCount(response?.dataCount);
                setTotalPage(response?.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    // Handle page change logic
    const handlePageChange = (pageNum) => {
        // Ensure the page number is within range
        if (pageNum < 1 || pageNum > totalPage) return;

        setCurrentPage(pageNum);
        // Perform any additional data fetching or state update logic here
    };

    const handleClose = () => {
        setModalShow(false);
    }

    useEffect(() => {
        if (isExportStatus !== null) {
            handleExport();
        }
    }, [isExportStatus]);

    const handleExport = async () => {
        let url = `export-user?all=${isExportStatus}`;
        await getApi(url, true).then(async (response) => {
            if (response.status === 200) {
                // Fetch the CSV file
                const responseVal = await fetch(`${process.env.REACT_APP_UPLOAD_URL}exports_file/${response?.data}`);
                const blob = await responseVal.blob();

                // Create a link element
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${response?.data}.csv`; // You can set a custom filename here

                // Append the link to the body (not visible)
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Clean up
                document.body.removeChild(link);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleWinner = async (id, is_win) => {
        let url = `winner-user?winner_id=${id}`;
        let values = {
            is_winner: parseInt(is_win) === 0 ? 1 : 0
        }

        await postApi(url, values, true)
            .then((response) => {
                if (response.status === 200) {
                    setUserData((prevWinnerData) =>
                        prevWinnerData.map(winner =>
                            winner.id === id ? { ...winner, is_winner: parseInt(is_win) === 0 ? 1 : 0 } : winner
                        )
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard /</Link></span> Users</p>
                <div className="d-flex justify-content-between align-items-center">
                    <input type="search" placeholder="Searching...." class="search-input me-3 w-auto" name="title" value={searchData} onChange={(e) => setSearchData(e.target.value)} />
                    <button className="theme-icon-btn float-end px-3 me-3" onClick={() => setModalShow(true)}><img src={ExportSvg} alt="setting icon" />Export</button>
                    <Link to={'/add-user'} className="theme-icon-btn float-end"><img src={AddIconSvg} alt="add icon" />Add User</Link>
                </div>
            </div>
            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className="text-center">Rank</th>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th className="text-center">Coins</th>
                            <th className="text-center">City</th>
                            <th className="text-center">County</th>
                            <th className="text-center">County Code</th>
                            <th className="text-center">Pincode</th>
                            <th className="text-center">Phone Number</th>
                            <th className="text-center">Login Type</th>
                            <th className="text-center">User Status</th>
                            <th className="text-center">Created At</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? userData?.length ? userData?.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="text-center">{val?.rank}</td>
                                        <td>{val?.full_name}</td>
                                        <td>{val?.email}</td>
                                        <td className="text-center">{val?.coins ? val?.coins : 0}</td>
                                        <td className="text-center">{val?.city}</td>
                                        <td className="text-center">{val?.country}</td>
                                        <td className="text-center">{val?.country_code}</td>
                                        <td className="text-center">{val?.pincode}</td>
                                        <td className="text-center">{val?.phone_number}</td>
                                        <td className="text-center">{val?.is_login === 1 ? 'Google' : val?.is_login === 2 ? 'Apple' : val?.is_login === 3 ? 'Facebook' : val?.is_login === 4 ? 'Twitter' : 'Not Logged'}</td>
                                        <td className="text-center">{val?.is_deleted ? <span className="badge bg-danger">Deleted</span> : <span className="badge bg-success">Active</span>}</td>
                                        <td className="text-center">{localTimeing(val?.createdAt)}</td>
                                        <td className="text-center">
                                            <div className="win-icons">
                                                {parseInt(val?.is_winner) === 0 ? <img src={WinnerSvg} alt="winner icon" onClick={() => handleWinner(val?.id, parseInt(val?.is_winner))} /> :
                                                    <img src={WinnerYellowSvg} alt="winner icon" onClick={() => { handleWinner(val?.id, parseInt(val?.is_winner)) }} />}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={13}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={13} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>

            {/* Render Pagination component */}
            <Pagination
                dataCount={dataCount}
                currentPage={currentPage}
                totalPage={totalPage}
                page={handlePageChange}
            />

            <Modal show={modalShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="theme-mobel-header">
                        <p className="header-title">Export Address</p>
                    </div>
                    <div className="theme-mobel-body">
                        <div className={`export-btn mb-4`} onClick={() => { setIsExportStatus(0) }}>
                            <span>
                                <img src={LightExportSvg} alt="export svg" />
                            </span>
                            <p>Full Name And Mobile Number in CSV format</p>
                        </div>
                        <div className="export-btn" onClick={() => { setIsExportStatus(1) }}>
                            <span>
                                <img src={LightExportSvg} alt="export svg" />
                            </span>
                            <p>All Data CSV format</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Users;