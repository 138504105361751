
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

// SVGs
import deleteSvg from "../../assets/assets_svg/delete.svg";
import sendNotificationSvg from "../../assets/assets_svg/send-notification.svg";
import PrevBannerSvg from '../../assets/assets_svg/prevbanner.svg'

import { DeleteConformation } from "../DeleteConformation";
import { getApi, postApi } from "../../Helpers/Axios";
import { Pagination } from "../Pagination";

const TermsConditions = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);

    const [notifyData, setNotifyData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [delId, setDelId] = useState(0);

    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');
    const [preview, setPreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        setIsLoadingData(true);
        fetchNotify();
    }, [currentPage, searchData])

    useEffect(() => {
        if (!modalShow) {
            fetchNotify();
        }
    }, [modalShow])

    const fetchNotify = async () => {
        let url = searchData !== '' ? `notification?page=${currentPage}&search=${searchData}` : `notification?page=${currentPage}`
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setNotifyData(response.data);
                setDataCount(response.dataCount);
                setTotalPage(response.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handlePageChange = (pageNum) => {
        // Ensure the page number is within range
        if (pageNum < 1 || pageNum > totalPage) return;

        setCurrentPage(pageNum);
        // Perform any additional data fetching or state update logic here
    };

    const [initialValues, setInitialValueData] = useState({
        notify_title: null,
        notify_msg: null,
        notify_img: null,
        status: 0,
    });

    const validationSchema = Yup.object({
        notify_title: Yup.string()
            .required("Notification title is required."),
        notify_msg: Yup.string()
            .required("Notification message is required."),
        notify_img: Yup.mixed()
            .nullable()
            .test("fileRequired", "An image is required.", function (value) {
                if (!value) {
                    return false;
                }
                return true;
            })
            .test("fileFormat", "Unsupported Format", function (value) {
                if (!value && (["image/jpg", "image/jpeg", "image/png"].includes(value?.type))) {
                    return false;
                }
                return true;
            })
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.status = values.status ? 1 : 0;
            let url = 'send-notification';
            await postApi(url, values, true, true)
                .then((response) => {
                    if (response?.status === 200) {
                        fetchNotify();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setFieldValue("notify_img", file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleRecentNotify = async (id) => {
        let url = `notification?resent_id=${id}`
        await getApi(url, true).then((response) => {
            if (response.status === 200) {

            } else {
                setIsLoadingData(false);
            }
        })
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard</Link> /</span> Notification</p>
            </div>

            <div className="notification-form w-50 m-auto">
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className='notify-preview-box'>
                        {!preview ?
                            <img src={PrevBannerSvg} alt='Add Banner Icon' className='add-icon'></img> :
                            <img src={preview} alt='Add Banner Icon' className='default-img-preview'></img>
                        }
                        <input type='file' id='upload-preview-images' onChange={handleImageChange} onBlur={handleBlur} className='d-none' />
                    </div>
                    {(errors?.notify_img && touched?.notify_img) ? <span className='require-text'>{errors?.notify_img}</span> : null}
                    <label className='upload-preview-link theme-btn' htmlFor='upload-preview-images'>upload Notification Image</label>
                    <div class="form-with-lable mb-3">
                        <label class="mt-1 form_label">Notification Title</label>
                        <div class="input-box">
                            <input type="text" name="notify_title" placeholder="Enter text" onChange={handleChange} onBlur={handleBlur} value={values?.notify_title} />
                        </div>
                        {(errors?.notify_title && touched?.notify_title) ? <span className='require-text'>{errors?.notify_title}</span> : null}
                    </div>
                    <div class="form-with-lable mb-3">
                        <label class="mt-1 form_label">Notification Message</label>
                        <div class="input-box">
                            <textarea type="text" name="notify_msg" rows={4} placeholder="Enter message" onChange={handleChange} onBlur={handleBlur} value={values?.notify_msg} />
                        </div>
                        {(errors?.notify_msg && touched?.notify_msg) ? <span className='require-text'>{errors?.notify_msg}</span> : null}
                    </div>
                    <div className="text-end">
                        <button type="submit" className="theme-btn">Send Notification</button>
                    </div>
                </form>
            </div>
            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ width: "60px" }}>#</th>
                            <th>Notification Image</th>
                            <th>Notification Title</th>
                            <th>Notification Message</th>
                            <th className="text-center" style={{ width: "140px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? notifyData?.length ? notifyData?.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val?.id}</td>
                                        <td>
                                            <div className="data-notify-image">
                                                <img src={`${process.env.REACT_APP_UPLOAD_URL + 'notifications/' + val.notify_img}`} alt="" />
                                            </div>
                                        </td>
                                        <td>{val?.notify_title}</td>
                                        <td>{val?.notify_msg}</td>
                                        <td>
                                            <div className="data-action">
                                                <span className="action-icon" onClick={() => handleRecentNotify(val.id)}>
                                                    <img src={sendNotificationSvg} alt="notification svg" />
                                                </span>
                                                <span className="action-icon" onClick={() => { setModalShow(true); setDelId(val.id) }}>
                                                    <img src={deleteSvg} alt="delete svg" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={5}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={5} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>

            {/* Render Pagination component */}
            <Pagination
                dataCount={dataCount}
                currentPage={currentPage}
                totalPage={totalPage}
                page={handlePageChange}
            />

            <DeleteConformation
                show={modalShow}
                onHide={() => setModalShow(false)}
                title='notification'
                url={`delete-notification?del_id=${delId}`}
            />

        </>
    )
}

export default TermsConditions;