import React, { useEffect, useState } from 'react'
import PrevBannerSvg from '../../assets/assets_svg/prevbanner.svg'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

import { getApi, postApi } from "../../Helpers/Axios";

const Addform = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [previewInstallWin, setPreviewInstallWin] = useState(null);

    useEffect(() => {
        if (searchParams.get('edit_id')) {
            handleEdit()
        }
    }, [searchParams.get('edit_id')])

    const handleEdit = async () => {
        let url = `get-install-win?edit_id=${searchParams.get('edit_id')}`;
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setInitialValueData(response?.data)
                setPreviewInstallWin(`${process.env.REACT_APP_UPLOAD_URL}app_logo/${response?.data?.app_logo}`)
            }
        })
    }

    const [initialValues, setInitialValueData] = useState({
        app_name: null,
        app_logo: null,
        app_url: null,
        coins: null,
        status: 0,
    });

    const validationSchema = Yup.object({
        app_name: Yup.string()
            .required("App name is required."),
        app_url: Yup.string()
            .required("App URL is required."),
        coins: Yup.string()
            .required("Coins is required."),
        app_logo: Yup.mixed()
            .nullable()
            .test("fileRequired", "An image is required.", function (value) {
                if (!value) {
                    return false;
                }
                return true;
            })
            .test("fileFormat", "Unsupported Format", function (value) {
                if (!value && (["image/jpg", "image/jpeg", "image/png"].includes(value?.type))) {
                    return false;
                }
                return true;
            }),
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.status = values.status ? 1 : 0;
            let url = searchParams.get('edit_id') ? `add-install-win?edit_id=${searchParams.get('edit_id')}` : 'add-install-win';
            await postApi(url, values, true, true)
                .then((response) => {
                    if (response?.status === 200) {
                        navigate("/install-and-win");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setFieldValue("app_logo", file);
            setPreviewInstallWin(URL.createObjectURL(file));
        }
    };

    return (
        <>
            <div>
                <p className='breadcrumbs'><Link to='/dashboard'><span>Dashboard</span></Link> / <Link to='/install-and-win'><span>Install & Win</span></Link> / <span>Add</span></p>
            </div>
            <div className='d-flex justify-content-around'>
                <div className=''>
                    <div className='banner-preview-box'>
                        {!previewInstallWin ?
                            <img src={PrevBannerSvg} alt='Add App logo' className='add-icon'></img> :
                            <img src={previewInstallWin} alt='Add App logo' className='default-img-preview'></img>
                        }
                        <input type='file' id='upload-preview-banner' onChange={handleImageChange} onBlur={handleBlur} className='d-none' />
                    </div>
                    {(errors?.banner && touched?.banner) ? <span className='require-text'>{errors?.banner}</span> : null}
                    <label className='upload-preview-link theme-btn' htmlFor='upload-preview-banner'>upload App Logo</label>
                </div>
            </div>
            <div>
                <form method='post' className='add-detail-main' onSubmit={handleSubmit} encType='multipart/form-data' autoComplete='off'>
                    <div className="row">
                        <div className="col-md-6">
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">App name</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter App name' name='app_name' onChange={handleChange} onBlur={handleBlur} value={values?.app_name} />
                                </div>
                            </div>
                            {(errors?.app_name && touched?.app_name) ? <span className='require-text text-start'>{errors?.app_name}</span> : null}
                        </div>
                        <div className="col-md-6">
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">coins</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter coins' name='coins' onChange={handleChange} onBlur={handleBlur} value={values?.coins} />
                                </div>
                            </div>
                            {(errors?.coins && touched?.coins) ? <span className='require-text text-start'>{errors?.coins}</span> : null}
                        </div>
                        <div className="col-md-12">
                            <div class="form-with-lable mt-2">
                                <label class="mt-1 form_label">App URL</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter App URL' name='app_url' onChange={handleChange} onBlur={handleBlur} value={values?.app_url} />
                                </div>
                            </div>
                            {(errors?.app_url && touched?.app_url) ? <span className='require-text text-start'>{errors?.app_url}</span> : null}
                        </div>
                    </div>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Status"
                        className='float-end mt-2'
                        checked={values?.status}
                        onChange={(e) => setFieldValue('status', !values?.status)}
                    />
                    <div className='text-center mt-4'>
                        <button type='submit' className='theme-btn px-4'>Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Addform