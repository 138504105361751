
// SVGs
import { useEffect, useState } from "react";
import UsersSvg from "../../assets/assets_svg/users.svg";

import { getApi, postApi } from "../../Helpers/Axios";

const Dashboard = () => {

   const [totalUsersCount, setTotalUsersCount] = useState(0);
   const [winnerUsersCount, setWinnerUsersCount] = useState(0);
   const [last60Minutes, setLast60Minutes] = useState(0);
   const [usersToday, setUsersToday] = useState(0);
   const [usersYesterday, setUsersYesterday] = useState(0);
   const [usersLastWeek, setUsersLastWeek] = useState(0);
   const [usersThisMonth, setUsersThisMonth] = useState(0);

   useEffect(() => {
      fetchUsers();
   }, [])

   const fetchUsers = async () => {
      let url = `get-dashboard`
      await getApi(url, true).then(async (response) => {
         if (response.status === 200) {
            setTotalUsersCount(response.data.totalUsersCount)
            setWinnerUsersCount(response.data.winnerUsersCount)
            setLast60Minutes(response.data.usersLast60Minutes)
            setUsersToday(response.data.usersToday)
            setUsersYesterday(response.data.usersYesterday)
            setUsersLastWeek(response.data.usersLastWeek)
            setUsersThisMonth(response.data.usersThisMonth)
         }
      })
   }

   return (
      <>
         <div className=" row">
            <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{totalUsersCount}</p>
                     <p className="box-title">All Users</p>
                  </div>
               </div>
            </div>
            <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{last60Minutes}</p>
                     <p className="box-title">Hourly</p>
                  </div>
               </div>
            </div>
            <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{usersToday}</p>
                     <p className="box-title">Today</p>
                  </div>
               </div>
            </div>
            <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{usersYesterday}</p>
                     <p className="box-title">Yesterday</p>
                  </div>
               </div>
            </div>
            <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{usersLastWeek}</p>
                     <p className="box-title">Weekly</p>
                  </div>
               </div>
            </div>
            <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{usersThisMonth}</p>
                     <p className="box-title">Monthly</p>
                  </div>
               </div>
            </div>
            {/* <div className="col-md-2">
               <div className="dash-box">
                  <div className="dash-box-icons">
                     <img src={UsersSvg} alt="user svg" />
                  </div>
                  <div className="dash-box-info">
                     <p className="box-count">{winnerUsersCount}</p>
                     <p className="box-title">Winners User</p>
                  </div>
               </div>
            </div> */}
         </div>
      </>
   )
}

export default Dashboard;