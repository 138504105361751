
const localTimeing = (timestamp) => {
    const date = new Date(timestamp);

    // Format the date as "DD Mon YYYY HH:MM:SS"
    const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true // For 24-hour format
    };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
};

export { localTimeing };