import React, { useEffect, useState } from 'react';
import DeleteSvg from '../../assets/assets_svg/delete.svg';
import eyeSvg from "../../assets/assets_svg/eye.svg";
import eyeHideSvg from "../../assets/assets_svg/eyehide.svg";
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

import { getApi, postApi } from "../../Helpers/Axios";

const Addform = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedImages, setSelectedImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);

    useEffect(() => {
        if (searchParams.get('edit_id')) {
            handleEdit()
        }
    }, [searchParams.get('edit_id')])

    // Function to handle editing and load images with IDs from the server
    const handleEdit = async () => {
        let url = `get-products?edit_id=${searchParams.get('edit_id')}`;

        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setInitialValueData(response?.data);

                // Map over the product_img array and store each image's id and URL
                if (response?.data?.product_img?.length > 0) {
                    const imageData = response?.data?.product_img.map((image) => ({
                        id: image.id, // Store the image ID
                        url: `${process.env.REACT_APP_UPLOAD_URL}products/${image.images}`, // Store the full image URL
                        is_thumb: image.is_thumbnail
                    }));
                    // Set preview images with id and url
                    setPreviewImages(imageData);
                }
            }
        });
    };

    const [initialValues, setInitialValueData] = useState({
        product_name: null,
        product_price: null,
        product_img: null,
        product_desc: null,
        sort: 1,
        status: 0,
    });

    const validationSchema = Yup.object({
        product_name: Yup.string()
            .required("Product name is required."),
        product_price: Yup.string()
            .matches(/^\d+(\.\d{1,2})?$/, "Product price must be a valid number with up to 2 decimal places.")
            .required("Product price is required."),
        product_desc: Yup.string()
            .required("Product description is required."),
        sort: Yup.string()
            .required("Product sorting is required."),
        product_img: Yup.mixed()
            .nullable()
            .test("fileRequired", "An image is required.", function (value) {
                if (!value) {
                    return false;
                }
                return true;
            })
            .test("fileFormat", "Unsupported Format", function (value) {
                if (!value && (["image/jpg", "image/jpeg", "image/png"].includes(value?.type))) {
                    return false;
                }
                return true;
            }),
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            // Convert the values object into FormData
            const formData = new FormData();

            // Append regular form fields (except the file input)
            formData.append('product_name', values.product_name); // Replace with actual field product_name
            formData.append('product_price', values.product_price); // Replace with actual field product_price
            formData.append('product_desc', values.product_desc); // Replace with actual field product_desc
            formData.append('sort', values.sort); // Replace with actual field sort
            formData.append('status', values.status ? 1 : 0); // Example of status field

            // Append files (product_img)
            console.log("🚀 ~ onSubmit: ~ values.product_img:", values.product_img)
            if (values.product_img && values.product_img.length > 0) {
                values.product_img.forEach((file) => {
                    formData.append('product_img', file); // Append all files under the same field 'product_img'
                });
            }

            // Determine the URL (edit or add)
            let url = searchParams.get('edit_id')
                ? `add-products?edit_id=${searchParams.get('edit_id')}`
                : 'add-products';

            // Use FormData in the post request
            await postApi(url, formData, true, true) // Ensure your postApi function can handle FormData
                .then((response) => {
                    if (response?.status === 200) {
                        navigate("/products"); // Redirect to the products page on success
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    // Function to handle image change and store multiple images with temporary IDs
    const handleImageChangeProduct = (e) => {
        const files = Array.from(e.target.files);

        // Generate temporary IDs for newly added images
        const newImages = files.map((file, index) => ({
            id: `temp-${selectedImages.length + index}`, // Temporary ID until saved to the server
            file: file,
            url: URL.createObjectURL(file) // Generate preview URL for the image
        }));

        // Update selectedImages and previewImages states
        const updatedSelectedImages = [...selectedImages, ...newImages];
        const updatedPreviewImages = [...previewImages, ...newImages];

        setSelectedImages(updatedSelectedImages);
        setPreviewImages(updatedPreviewImages);

        // Update the field value in form for submission
        setFieldValue("product_img", updatedSelectedImages.map(image => image.file)); // Pass only file objects to form submission
    };

    // Function to remove a specific image (with API for edit mode and without API for newly added images)
    const removeImage = async (indexToRemove, imageId) => {
        // Check if the image has an ID and is part of an edited product
        // if (imageId && !imageId.startsWith('temp-')) {
        if (imageId) {
            try {
                // Call the delete-product-image API
                let deleteUrl = `delete-product-image?image_id=${imageId}`; // Assuming image_id is the identifier in your API
                const response = await getApi(deleteUrl, true); // Replace with your actual API call function

                if (response.status === 200) {
                    // If deletion is successful, update the state to remove the image from preview
                    const updatedPreviewImages = previewImages.filter((_, index) => index !== indexToRemove);
                    setPreviewImages(updatedPreviewImages);

                    const updatedSelectedImages = selectedImages.filter((_, index) => index !== indexToRemove);
                    setSelectedImages(updatedSelectedImages);

                    // Update the field value after removal
                    setFieldValue("product_img", updatedSelectedImages);
                } else {
                    // If imageId doesn't exist or is a temporary ID, remove it directly from the preview
                    const updatedSelectedImages = selectedImages.filter((_, index) => index !== indexToRemove);
                    setSelectedImages(updatedSelectedImages);

                    const updatedPreviewImages = previewImages.filter((_, index) => index !== indexToRemove);
                    setPreviewImages(updatedPreviewImages);

                    // Update the field value after removal
                    setFieldValue("product_img", updatedSelectedImages);
                }
            } catch (error) {
                console.error("Error occurred while calling delete API:", error);
            }
        }
    };

    const handleThumbnailImg = async (id, status) => {
        // Determine the URL edit
        let url = `thumbnail-image?edit_id=${id}`;
        let formData = { is_thumbnail: parseInt(status) ? 0 : 1 }

        // Use FormData in the post request
        await postApi(url, formData, true) // Ensure your postApi function can handle FormData
            .then((response) => {
                if (response?.status === 200) {
                    setPreviewImages((prevproductData) => {
                        return prevproductData.map(proThumb =>
                            proThumb.id === id ? { ...proThumb, is_thumb: formData.is_thumbnail } : proThumb
                        );
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <div>
                <p className='breadcrumbs'><Link to='/dashboard'><span>Dashboard</span></Link> / <Link to='/products'><span>Product</span></Link> / <span>Add</span></p>
            </div>
            <div className=''>
                {previewImages.length ? <div className='product-preview-images d-flex gap-3 w-75 m-auto p-2 bg-body-secondary rounded-2 flex-wrap'>
                    {previewImages.map((imageSrc, index) => (
                        <div className='preview-images'>
                            <img src={imageSrc.url} alt={`Preview ${index}`} />
                            <div className='del-preview-image' onClick={() => removeImage(index, imageSrc.id)}>
                                <img src={DeleteSvg} alt='' />
                            </div>
                            {(searchParams.get('edit_id') && 'is_thumb' in imageSrc) && <div className='thumb-image-status' onClick={() => handleThumbnailImg(imageSrc.id, imageSrc.is_thumb)}>
                                <img src={imageSrc.is_thumb === 1 ? eyeSvg : eyeHideSvg} alt='' />
                            </div>}
                        </div>
                    ))}
                </div> : ''}
                <div className=''>
                    <input type='file' id='upload-preview-product' name="product_img" multiple onChange={handleImageChangeProduct} onBlur={handleBlur} className='d-none' />
                    {(errors?.banner && touched?.banner) ? <span className='require-text'>{errors?.banner}</span> : null}
                    <label className='upload-preview-link theme-btn' htmlFor='upload-preview-product'>upload product </label>
                </div>
            </div>
            <div>
                <form method='post' className='add-detail-main' onSubmit={handleSubmit} encType='multipart/form-data' autoComplete='off'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">Product name</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter product name' name='product_name' onChange={handleChange} onBlur={handleBlur} value={values?.product_name} />
                                </div>
                            </div>
                            {(errors?.product_name && touched?.product_name) ? <span className='require-text text-start mt-1'>{errors?.product_name}</span> : null}
                        </div>
                        <div className='col-md-4'>
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">Product price</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter product price' name='product_price' onChange={handleChange} onBlur={handleBlur} value={values?.product_price} />
                                </div>
                            </div>
                            {(errors?.product_price && touched?.product_price) ? <span className='require-text text-start mt-1'>{errors?.product_price}</span> : null}
                        </div>
                        <div className='col-md-4'>
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">Product sorting</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter product sorting' name='sort' onChange={handleChange} onBlur={handleBlur} value={values?.sort} />
                                </div>
                            </div>
                            {(errors?.sort && touched?.sort) ? <span className='require-text text-start mt-1'>{errors?.sort}</span> : null}
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div class="form-with-lable">
                                <label class="form_label">Product Description</label>
                                <div class="input-box">
                                    <textarea type='text' rows={4} placeholder='Enter product description' name='product_desc' onChange={handleChange} onBlur={handleBlur} value={values?.product_desc} />
                                </div>
                            </div>
                            {(errors?.product_desc && touched?.product_desc) ? <span className='require-text text-start'>{errors?.product_desc}</span> : null}
                        </div>
                    </div>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Status"
                        className='float-end mt-2'
                        checked={values?.status}
                        onChange={(e) => setFieldValue('status', !values?.status)}
                    />
                    <div className='text-center mt-4'>
                        <button type='submit' className='theme-btn px-4'>Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Addform