import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Redux store
import { store } from './Helpers/Redux/store';
// Redux store ---- End

// Components
import Layout from "./Layout/index";
import SignIn from './components/Auth/SignIn';
import Dashboard from './components/Dashboard';
import Phone from './components/Phone';
import AddPhone from './components/Phone/addphoneform';
import WhatsappText from './components/Whatsapp';
import AddWhatsappBtn from './components/Whatsapp/addwhatsappform';
import Task from './components/Task';
import Addtask from './components/Task/addtask';
import Users from './components/Users';
import AddUserUsers from './components/Users/addUser';
import Products from './components/Products';
import AddProducts from './components/Products/addProductform';
import TermsConditions from './components/TermsConditions';
import Notification from './components/Notification';
import Orders from './components/Orders';
import Games from './components/Games';
import AddGames from './components/Games/addGameform';
import Earn from './components/Earns';
import AddEarn from './components/Earns/addEarnform';
import UnlimitedEarn from './components/UnlimitedEarn';
import AddUnlimitedEarn from './components/UnlimitedEarn/addunlimitedearn';
import InstallWin from './components/InstallWin';
import AddInstallWin from './components/InstallWin/addinstallwinform';
// Components ---- End

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Layout><Dashboard /></Layout>,
  },
  {
    path: "/phones",
    element: <Layout><Phone /></Layout>,
  },
  {
    path: "/add-phones",
    element: <Layout><AddPhone /></Layout>,
  },
  {
    path: "/whatsapp-share-text",
    element: <Layout><WhatsappText /></Layout>,
  },
  {
    path: "/add-whatsapp-text",
    element: <Layout><AddWhatsappBtn /></Layout>,
  },
  {
    path: "/task",
    element: <Layout><Task /></Layout>,
  },
  {
    path: "/add-task",
    element: <Layout><Addtask /></Layout>,
  },
  {
    path: "/users",
    element: <Layout><Users /></Layout>,
  },
  {
    path: "/add-user",
    element: <Layout><AddUserUsers /></Layout>,
  },
  {
    path: "/games",
    element: <Layout><Games /></Layout>,
  },
  {
    path: "/add-game",
    element: <Layout><AddGames /></Layout>,
  },
  {
    path: "/earns",
    element: <Layout><Earn /></Layout>,
  },
  {
    path: "/add-earns",
    element: <Layout><AddEarn /></Layout>,
  },
  {
    path: "/unlimited-earn",
    element: <Layout><UnlimitedEarn /></Layout>,
  },
  {
    path: "/add-unlimited-earn",
    element: <Layout><AddUnlimitedEarn /></Layout>,
  },
  {
    path: "/install-and-win",
    element: <Layout><InstallWin /></Layout>,
  },
  {
    path: "/add-install-and-win",
    element: <Layout><AddInstallWin /></Layout>,
  },
  {
    path: "/products",
    element: <Layout><Products /></Layout>,
  },
  {
    path: "/add-product",
    element: <Layout><AddProducts /></Layout>,
  },
  {
    path: "/orders",
    element: <Layout><Orders /></Layout>,
  },
  {
    path: "/terms-and-conditions",
    element: <Layout><TermsConditions /></Layout>,
  },
  {
    path: "/notification",
    element: <Layout><Notification /></Layout>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);