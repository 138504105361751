
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, Table } from 'react-bootstrap';

// SVGs
import PlusSvg from "../../assets/assets_svg/addicon.svg";
import editSvg from "../../assets/assets_svg/edit.svg";
import deleteSvg from "../../assets/assets_svg/delete.svg";
import eyeSvg from "../../assets/assets_svg/eye.svg";
import eyeHideSvg from "../../assets/assets_svg/eyehide.svg";

import { DeleteConformation } from "../DeleteConformation";
import { getApi, postApi } from "../../Helpers/Axios";
import { Pagination } from "../Pagination";

const Products = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [proImagesShow, setProImagesShow] = useState(false);

    const [productData, setProductData] = useState(null);
    const [proImgData, setProImgData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [delId, setDelId] = useState(0);

    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');

    const [productName, setProductName] = useState(null);

    useEffect(() => {
        setIsLoadingData(true);
        fetchProduct();
    }, [currentPage, searchData])

    useEffect(() => {
        if (!modalShow) {
            fetchProduct();
        }
    }, [modalShow])

    const fetchProduct = async () => {
        let url = searchData !== '' ? `get-products?page=${currentPage}&search=${searchData}` : `get-products?page=${currentPage}`
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setProductData(response?.data);
                setDataCount(response?.dataCount);
                setTotalPage(response?.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleStatus = async (id, status) => {
        let url = `status-products?status_id=${id}`;
        let bodyData = { "status": status === 1 ? 0 : 1 }
        await postApi(url, bodyData, true)
            .then(async (response) => {
                if (response.status === 200) {
                    setProductData((prevproductData) =>
                        prevproductData.map(banner =>
                            banner.id === id ? { ...banner, status: bodyData?.status } : banner
                        )
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePageChange = (pageNum) => {
        // Ensure the page number is within range
        if (pageNum < 1 || pageNum > totalPage) return;

        setCurrentPage(pageNum);
        // Perform any additional data fetching or state update logic here
    };

    const handleProductImg = async (id, product) => {
        setProductName(product);
        let url = `product-images?pro_id=${id}`;
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setProImgData(response?.data);
                setProImagesShow(true);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleThumbnailImg = async (id, status) => {
        // Determine the URL edit
        let url = `thumbnail-image?edit_id=${id}`;
        let formData = { is_thumbnail: parseInt(status) ? 0 : 1 }

        // Use FormData in the post request
        await postApi(url, formData, true) // Ensure your postApi function can handle FormData
            .then((response) => {
                if (response?.status === 200) {
                    setProImgData((prevproductData) =>
                        prevproductData.map(proThumb =>
                            proThumb.id === id ? { ...proThumb, is_thumbnail: formData.is_thumbnail } : proThumb
                        )
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard</Link> /</span> Products</p>
                <div className="d-flex justify-content-between align-items-center">
                    <input type="search" placeholder="Searching...." class="search-input me-3" name="title" value={searchData} onChange={(e) => setSearchData(e.target.value)} />
                    <Link to={'/add-product'}><button className="theme-icon-btn float-end px-3"><img src={PlusSvg} alt="setting icon" />Add</button></Link>
                </div>
            </div>

            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ "width": "60px" }}>#</th>
                            <th style={{ "width": "160px" }} className="text-center">Product Img</th>
                            <th>Product Name</th>
                            <th className="text-center">Product Price</th>
                            <th className="text-center">Position</th>
                            <th className="text-center" style={{ width: "140px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? productData?.length ? productData?.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val?.id}</td>
                                        <td>
                                            <div className="data-products" onClick={() => handleProductImg(val?.id, val?.product_name)}>
                                                <img src={`${process.env.REACT_APP_UPLOAD_URL + 'products/' + val.product_image}`} alt="" />
                                            </div>
                                        </td>
                                        <td>{val?.product_name}</td>
                                        <td style={{ "width": "130px" }} className="text-center" s>{val?.product_price}</td>
                                        <td style={{ "width": "50px" }} className="text-center">{val?.sort}</td>
                                        <td>
                                            <div className="data-action">
                                                <span className="action-icon" onClick={() => navigate(`/add-product?edit_id=${val.id}`)}><img src={editSvg} alt="" /></span>
                                                <span className="action-icon" onClick={() => { setModalShow(true); setDelId(val.id) }}><img src={deleteSvg} alt="" /></span>
                                                <span className="action-icon" onClick={() => handleStatus(val?.id, val?.status)}>
                                                    {val.status ? <img src={eyeSvg} alt="" /> : <img src={eyeHideSvg} alt="" />}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={6}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={6} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>

            {/* Render Pagination component */}
            <Pagination
                dataCount={dataCount}
                currentPage={currentPage}
                totalPage={totalPage}
                page={handlePageChange}
            />

            <DeleteConformation
                show={modalShow}
                onHide={() => setModalShow(false)}
                title='product'
                url={`delete-products?del_id=${delId}`}
            />

            <Modal
                show={proImagesShow}
                onHide={() => setProImagesShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {productName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex gap-2">
                        {proImgData?.map((imageSrc, index) => (
                            <div className='preview-images'>
                                <img src={`${process.env.REACT_APP_UPLOAD_URL}products/${imageSrc.images}`} alt={`Preview ${index}`} />
                                <div className='del-preview-image' onClick={() => handleThumbnailImg(imageSrc.id, imageSrc.is_thumbnail)}>
                                    {imageSrc.is_thumbnail ? <img src={eyeSvg} alt='' /> : <img src={eyeHideSvg} alt='' />}
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Products;