
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';

// SVGs
import PlusSvg from "../../assets/assets_svg/addicon.svg";
import editSvg from "../../assets/assets_svg/edit.svg";
import deleteSvg from "../../assets/assets_svg/delete.svg";
import eyeSvg from "../../assets/assets_svg/eye.svg";
import eyeHideSvg from "../../assets/assets_svg/eyehide.svg";

import { DeleteConformation } from "../DeleteConformation";
import { getApi, postApi } from "../../Helpers/Axios";
import { Pagination } from "../Pagination";

const Task = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);

    const [taskData, setTaskData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [delId, setDelId] = useState(0);

    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        setIsLoadingData(true);
        fetchTask();
    }, [currentPage, searchData])

    useEffect(() => {
        if (!modalShow) {
            fetchTask();
        }
    }, [modalShow])

    const fetchTask = async () => {
        let url = searchData !== '' ? `get-unlimited-earn?page=${currentPage}&search=${searchData}` : `get-unlimited-earn?page=${currentPage}`
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setTaskData(response.data);
                setDataCount(response.dataCount);
                setTotalPage(response.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleStatus = async (id, status) => {
        let url = `status-unlimited-earn?status_id=${id}`;
        let bodyData = { "status": status === 1 ? 0 : 1 }
        await postApi(url, bodyData, true)
            .then(async (response) => {
                if (response.status === 200) {
                    setTaskData((prevTaskData) =>
                        prevTaskData.map(task =>
                            task.id === id ? { ...task, status: bodyData.status } : task
                        )
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePageChange = (pageNum) => {
        // Ensure the page number is within range
        if (pageNum < 1 || pageNum > totalPage) return;

        setCurrentPage(pageNum);
        // Perform any additional data fetching or state update logic here
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard</Link> /</span> Unlimited Earn</p>
                <div className="d-flex justify-content-between align-items-center">
                    <input type="search" placeholder="Searching...." class="search-input me-3" name="title" value={searchData} onChange={(e) => setSearchData(e.target.value)} />
                    <Link to={'/add-unlimited-earn'}><button className="theme-icon-btn float-end px-3"><img src={PlusSvg} alt="setting icon" />Add</button></Link>
                </div>
            </div>

            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Task Title</th>
                            <th>Task</th>
                            <th>Coin</th>
                            <th className="text-center" style={{ width: "140px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? taskData?.length ? taskData?.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val?.task_title}</td>
                                        <td>{val?.task}</td>
                                        <td>{val?.coins}</td>
                                        <td>
                                            <div className="data-action">
                                                <span className="action-icon" onClick={() => navigate(`/add-unlimited-earn?edit_id=${val.id}`)}>
                                                    <img src={editSvg} alt="" />
                                                </span>
                                                <span className="action-icon" onClick={() => { setModalShow(true); setDelId(val.id) }}>
                                                    <img src={deleteSvg} alt="" />
                                                </span>
                                                <span className="action-icon" onClick={() => handleStatus(val?.id, val?.status)}>
                                                    {val.status ? <img src={eyeSvg} alt="" /> : <img src={eyeHideSvg} alt="" />}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={5}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={5} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>

            {/* Render Pagination component */}
            <Pagination
                dataCount={dataCount}
                currentPage={currentPage}
                totalPage={totalPage}
                page={handlePageChange}
            />

            <DeleteConformation
                show={modalShow}
                onHide={() => setModalShow(false)}
                title='Unlimited Earn'
                url={`delete-unlimited-earn?del_id=${delId}`}
            />

        </>
    )
}

export default Task;