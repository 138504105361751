import React, { useEffect, useState } from 'react'
import PrevBannerSvg from '../../assets/assets_svg/prevbanner.svg'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

import { getApi, postApi } from "../../Helpers/Axios";

const Addform = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [previewBanner, setPreviewBanner] = useState(null);
    const [previewPhone, setPreviewPhone] = useState(null);

    useEffect(() => {
        if (searchParams.get('edit_id')) {
            handleEdit()
        }
    }, [searchParams.get('edit_id')])

    const handleEdit = async () => {
        let url = `get-phone?editbanner=${searchParams.get('edit_id')}`;
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setInitialValueData(response?.data)
                setPreviewBanner(`${process.env.REACT_APP_UPLOAD_URL}banners/${response?.data?.banner}`)
                setPreviewPhone(`${process.env.REACT_APP_UPLOAD_URL}phones/${response?.data?.phones}`)
            }
        })
    }

    const [initialValues, setInitialValueData] = useState({
        phone_name: null,
        banner: null,
        phones: null,
        sort: 1,
        status: 0,
    });

    const validationSchema = Yup.object({
        phone_name: Yup.string()
            .required("Phone name is required."),
        sort: Yup.string()
            .required("sort number is required."),
        banner: Yup.mixed()
            .nullable()
            .test("fileRequired", "An image is required.", function (value) {
                if (!value) {
                    return false;
                }
                return true;
            })
            .test("fileFormat", "Unsupported Format", function (value) {
                if (!value && (["image/jpg", "image/jpeg", "image/png"].includes(value?.type))) {
                    return false;
                }
                return true;
            }),
        phones: Yup.mixed()
            .nullable()
            .test("fileRequired", "An image is required.", function (value) {
                if (!value) {
                    return false;
                }
                return true;
            })
            .test("fileFormat", "Unsupported Format", function (value) {
                if (!value && (["image/jpg", "image/jpeg", "image/png"].includes(value?.type))) {
                    return false;
                }
                return true;
            })
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.status = values.status ? 1 : 0;
            let url = searchParams.get('edit_id') ? `add-phone?edit_id=${searchParams.get('edit_id')}` : 'add-phone';
            await postApi(url, values, true, true)
                .then((response) => {
                    if (response?.status === 200) {
                        navigate("/phones");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const handleImageChangeBanner = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setFieldValue("banner", file);
            setPreviewBanner(URL.createObjectURL(file));
        }
    };

    const handleImageChangePhone = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setFieldValue("phones", file);
            setPreviewPhone(URL.createObjectURL(file));
        }
    };

    return (
        <>
            <div>
                <p className='breadcrumbs'><Link to='/dashboard'><span>Dashboard</span></Link> / <Link to='/phones'><span>Phones</span></Link> / <span>Add</span></p>
            </div>
            <div className='d-flex justify-content-around'>
                <div className=''>
                    <div className='phone-preview-box'>
                        {!previewPhone ?
                            <img src={PrevBannerSvg} alt='Add phone Icon' className='add-icon'></img> :
                            <img src={previewPhone} alt='Add phone Icon' className='default-img-preview'></img>
                        }
                        <input type='file' id='upload-preview-phone' onChange={handleImageChangePhone} onBlur={handleBlur} className='d-none' />
                    </div>
                    {(errors?.phones && touched?.phones) ? <span className='require-text'>{errors?.phones}</span> : null}
                    <label className='upload-preview-link theme-btn' htmlFor='upload-preview-phone'>upload Phone</label>
                </div>
                <div className=''>
                    <div className='banner-preview-box'>
                        {!previewBanner ?
                            <img src={PrevBannerSvg} alt='Add Banner Icon' className='add-icon'></img> :
                            <img src={previewBanner} alt='Add Banner Icon' className='default-img-preview'></img>
                        }
                        <input type='file' id='upload-preview-banner' onChange={handleImageChangeBanner} onBlur={handleBlur} className='d-none' />
                    </div>
                    {(errors?.banner && touched?.banner) ? <span className='require-text'>{errors?.banner}</span> : null}
                    <label className='upload-preview-link theme-btn' htmlFor='upload-preview-banner'>upload banner</label>
                </div>
            </div>
            <div>
                <form method='post' className='add-detail-main' onSubmit={handleSubmit} encType='multipart/form-data' autoComplete='off'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">Phone name</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter phone name' name='phone_name' onChange={handleChange} onBlur={handleBlur} value={values?.phone_name} />
                                </div>
                            </div>
                            {(errors?.phone_name && touched?.phone_name) ? <span className='require-text'>{errors?.phone_name}</span> : null}
                        </div>
                        <div className='col-md-6'>
                            <div class="form-with-lable">
                                <label class="mt-1 form_label">Sort Position</label>
                                <div class="input-box">
                                    <input type='text' placeholder='Enter position' name='sort' onChange={handleChange} onBlur={handleBlur} value={values?.sort} />
                                </div>
                            </div>
                            {(errors?.sort && touched?.sort) ? <span className='require-text'>{errors?.sort}</span> : null}
                        </div>
                    </div>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Status"
                        className='float-end mt-2'
                        checked={values?.status}
                        onChange={(e) => setFieldValue('status', !values?.status)}
                    />
                    <div className='text-center mt-4'>
                        <button type='submit' className='theme-btn px-4'>Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Addform