import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// React Redux
import { useSelector } from "react-redux";

// CSS
import "./layout.css";

// SVGs
import SideBarUserPreview from "../assets/sidebar-profile-preview.svg";
import BannerSvg from "../assets/assets_svg/banner.svg";
import WhatsappSvg from "../assets/assets_svg/whatsapp.svg";
import taskSvg from "../assets/assets_svg/task.svg";
import speedometerSvg from "../assets/assets_svg/speedometer.svg";
import termsConditionsSvg from "../assets/assets_svg/terms-conditions.svg";
import notificationSvg from "../assets/assets_svg/notification.svg";
import userSvg from "../assets/assets_svg/users.svg";
import productSvg from "../assets/assets_svg/products.svg";
import orderSvg from "../assets/assets_svg/order.svg";
import gameSvg from "../assets/assets_svg/game.svg";
import dollerSvg from "../assets/assets_svg/doller-coin.svg";
import subMenuSvg from "../assets/assets_svg/sub-menu-icon.svg";
import arrowLeftSvg from "../assets/assets_svg/arrow-left.svg";
import settingSvg from "../assets/assets_svg/setting.svg";
import playStoreSvg from "../assets/assets_svg/play-store.svg";
import deviceSvg from "../assets/assets_svg/device.svg";

const Sidebar = () => {
  const admin = useSelector((state) => state?.auth?.user);
  const location = useLocation();

  const [openMenus, setOpenMenus] = useState({});

  const toggleMenu = (menu) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-logo">PHONE WIN</div>
        <div className="side-profile-icon-info text-center">
          <div className="profile-pic">
            <img src={SideBarUserPreview} alt="user-preview" />
          </div>
          <Link className="user-name">{admin?.fullname}</Link>
        </div>
        <div className="sidebar-menus">
          <ul className="menu-list">
            {/* Dashboard */}
            <li className="menu-item">
              <Link
                to={"/dashboard"}
                className={`menu-link ${
                  location?.pathname === "/dashboard" && "active-link"
                }`}
              >
                <img src={speedometerSvg} alt="speedometer icon" />
                <p>Dashboard</p>
              </Link>
            </li>
            {/* Orders */}
            <li className="menu-item">
              <Link
                to={"/orders"}
                className={`menu-link ${
                  (location?.pathname === "/orders" ||
                    location?.pathname === "/orders") &&
                  "active-link"
                }`}
              >
                <img src={orderSvg} alt="setting icon" />
                <p>Orders</p>
              </Link>
            </li>
            {/* Products */}
            <li className="menu-item">
              <Link
                to={"/products"}
                className={`menu-link ${
                  location?.pathname === "/products" && "active-link"
                }`}
              >
                <img src={productSvg} alt="setting icon" />
                <p>Products</p>
              </Link>
            </li>
            {/* Users */}
            <li className="menu-item">
              <Link
                to={"/users"}
                className={`menu-link ${
                  location?.pathname === "/users" && "active-link"
                }`}
              >
                <img src={userSvg} alt="setting icon" />
                <p>Users</p>
              </Link>
            </li>
            {/* Banner */}
            <li className="menu-item">
              <Link
                to={"/phones"}
                className={`menu-link ${
                  (location?.pathname === "/phones" ||
                    location?.pathname === "/add-phones") &&
                  "active-link"
                }`}
              >
                <img src={deviceSvg} alt="setting icon" />
                <p>Phones</p>
              </Link>
            </li>
            {/* WhatsApp Share Text */}
            <li className="menu-item">
              <Link
                to={"/whatsapp-share-text"}
                className={`menu-link ${
                  (location?.pathname === "/whatsapp-share-text" ||
                    location?.pathname === "/add-whatsapp-text") &&
                  "active-link"
                }`}
              >
                <img src={WhatsappSvg} alt="setting icon" />
                <p>WhatsApp Share Text</p>
              </Link>
            </li>
            {/* Task */}
            <li className="menu-item">
              <Link
                to={"/task"}
                className={`menu-link ${
                  (location?.pathname === "/task" ||
                    location?.pathname === "/add-task") &&
                  "active-link"
                }`}
              >
                <img src={taskSvg} alt="setting icon" />
                <p>Task</p>
              </Link>
            </li>
            {/* Earn */}
            <li
              className={`menu-item menu-with-sub-item ${
                openMenus["menu1"] ||
                location?.pathname === "/earns" ||
                location?.pathname === "/add-earns" ||
                location?.pathname === "/games" ||
                location?.pathname === "/add-game" ||
                location?.pathname === "/unlimited-earn" ||
                location?.pathname === "/add-unlimited-earn" ||
                location?.pathname === "/install-and-win" ||
                location?.pathname === "/add-install-and-win"
                  ? "menu-should-openig"
                  : ""
              }`}
              onClick={() => toggleMenu("menu1")}
            >
              <Link to={"/earns"} className="menu-link">
                <img src={dollerSvg} alt="speedometer icon" />
                <p>
                  Earn
                  <img src={arrowLeftSvg} alt="" />
                </p>
              </Link>
              <ul
                className={`menu-sub-list ${
                  openMenus["menu1"] ||
                  location?.pathname === "/games" ||
                  location?.pathname === "/add-game" ||
                  location?.pathname === "/unlimited-earn" ||
                  location?.pathname === "/add-unlimited-earn" ||
                  location?.pathname === "/install-and-win" ||
                  location?.pathname === "/add-install-and-win"
                    ? "d-block"
                    : "d-none"
                }`}
              >
                {/* Unlimited Earn */}
                <li className="menu-sub-item">
                  <Link
                    className={`menu-sub-link ${
                      (location?.pathname === "/unlimited-earn" ||
                        location?.pathname === "/add-unlimited-earn") &&
                      "active-link"
                    }`}
                    to="/unlimited-earn"
                  >
                    <img src={dollerSvg} alt="speedometer icon" />
                    Unlimited Earn
                  </Link>
                </li>
                {/* Install And Win */}
                <li className="menu-sub-item">
                  <Link
                    className={`menu-sub-link ${
                      (location?.pathname === "/install-and-win" ||
                        location?.pathname === "/add-install-and-win") &&
                      "active-link"
                    }`}
                    to="/install-and-win"
                  >
                    <img src={playStoreSvg} alt="speedometer icon" />
                    Install And Win
                  </Link>
                </li>
                {/* Games */}
                <li className="menu-sub-item">
                  <Link
                    className={`menu-sub-link ${
                      (location?.pathname === "/games" ||
                        location?.pathname === "/add-game") &&
                      "active-link"
                    }`}
                    to="/games"
                  >
                    <img src={gameSvg} alt="speedometer icon" />
                    Games
                  </Link>
                </li>
              </ul>
            </li>
            {/* Setting */}
            <li
              className={`menu-item menu-with-sub-item ${
                openMenus["menu2"] ||
                location?.pathname === "/terms-and-conditions" ||
                location?.pathname === "/notification"
                  ? "menu-should-openig"
                  : ""
              }`}
              onClick={() => toggleMenu("menu2")}
            >
              <Link className="menu-link">
                <img src={settingSvg} alt="speedometer icon" />
                <p>
                  Setting
                  <img src={arrowLeftSvg} alt="" />
                </p>
              </Link>
              <ul
                className={`menu-sub-list ${
                  openMenus["menu2"] ||
                  location?.pathname === "/terms-and-conditions" ||
                  location?.pathname === "/notification"
                    ? "d-block"
                    : "d-none"
                }`}
              >
                {/* Terms & Conditions */}
                <li className="menu-sub-item">
                  <Link
                    className={`menu-sub-link ${
                      location?.pathname === "/terms-and-conditions" &&
                      "active-link"
                    }`}
                    to="/terms-and-conditions"
                  >
                    <img src={termsConditionsSvg} alt="speedometer icon" />
                    Terms & Conditions
                  </Link>
                </li>
                {/* Notification */}
                <li className="menu-sub-item">
                  <Link
                    className={`menu-sub-link ${
                      location?.pathname === "/notification" && "active-link"
                    }`}
                    to="/notification"
                  >
                    <img src={notificationSvg} alt="speedometer icon" />
                    Notification
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
