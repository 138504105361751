
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// SVGs
import PlusSvg from "../../assets/assets_svg/addicon.svg";
import deleteSvg from "../../assets/assets_svg/delete.svg";

import { DeleteConformation } from "../DeleteConformation";
import { getApi, postApi } from "../../Helpers/Axios";
import { localTimeing } from "../../Helpers/Helper";
import { Pagination } from "../Pagination";

const Orders = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);

    const [ordersData, setOrdersData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [delId, setDelId] = useState(0);

    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        setIsLoadingData(true);
        fetchOrders();
    }, [currentPage, searchData])

    useEffect(() => {
        if (!modalShow) {
            fetchOrders();
        }
    }, [modalShow]);

    // const notify = () => toast("Wow so easy!");

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const fetchOrders = async () => {
        let url = searchData !== '' ? `get-orders?page=${currentPage}&search=${searchData}` : `get-orders?page=${currentPage}`
        await getApi(url, true).then((response) => {
            if (response.status === 200) {
                setOrdersData(response?.data);
                setDataCount(response?.dataCount);
                setTotalPage(response?.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleOrderStatus = async (id, status) => {
        let url = `order-status?status_id=${id}`;
        let bodyData = { "status": status === 1 ? 0 : 1 }
        await postApi(url, bodyData, true)
            .then(async (response) => {
                if (response.status === 200) {
                    setOrdersData((prevOrderData) =>
                        prevOrderData.map(order =>
                            order.id === id ? { ...order, order_status: bodyData?.status } : order
                        )
                    );
                    notify(response.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePageChange = (pageNum) => {
        // Ensure the page number is within range
        if (pageNum < 1 || pageNum > totalPage) return;

        setCurrentPage(pageNum);
        // Perform any additional data fetching or state update logic here
    };

    return (
        <>
            <ToastContainer />
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs">
                    <span><Link to={'/dashboard'}>Dashboard</Link> /</span>
                    Order
                </p>
                <div className="d-flex justify-content-between align-items-center">
                    {/* <input type="search" placeholder="Searching...." class="search-input me-3" name="title" value={searchData} onChange={(e) => setSearchData(e.target.value)} /> */}
                </div>
            </div>

            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product Image</th>
                            <th>Products</th>
                            <th>Users</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Price</th>
                            <th>Address</th>
                            <th>Country</th>
                            <th>Pincode</th>
                            <th>Ordered Date</th>
                            <th className="text-center" style={{ width: "140px" }}>Order Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? ordersData?.length ? ordersData?.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val?.id}</td>
                                        <td>
                                            <div className="data-products">
                                                <img src={`${process.env.REACT_APP_UPLOAD_URL + 'products/' + val?.product?.product_img?.images}`} alt="" />
                                            </div>
                                        </td>
                                        <td>{val?.product?.product_name}</td>
                                        <td>{val?.user?.full_name}</td>
                                        <td>{val?.user?.email}</td>
                                        <td>{val?.user?.phone_number}</td>
                                        <td>{val?.product?.product_price}</td>
                                        <td>{val?.user?.address_line_1} {val?.user?.address_line_2}</td>
                                        <td>{val?.user?.country}</td>
                                        <td>{val?.user?.pincode}</td>
                                        <td>{localTimeing(val?.createdAt)}</td>
                                        <td className="text-center">
                                            {
                                                val?.order_status
                                                    ? <button className={`btn btn-success btn-sm px-3`}>Deliverd</button>
                                                    : <button className={`btn btn-warning btn-sm px-3`} onClick={() => handleOrderStatus(val?.id, val?.order_status)}>Pending</button>
                                            }
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={12}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={12} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>

            {/* Render Pagination component */}
            <Pagination
                dataCount={dataCount}
                currentPage={currentPage}
                totalPage={totalPage}
                page={handlePageChange}
            />

            <DeleteConformation
                show={modalShow}
                onHide={() => setModalShow(false)}
                title='product'
                url={`delete-products?del_id=${delId}`}
            />

        </>
    )
}

export default Orders;